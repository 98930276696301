.contenedor {
    background-image: url('../../Assets/fotoHero2.jpg');


    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

}

.informacion {
    color: white;
    display: flex;
    flex-direction: column;
    margin-left: 10px;
}

h5 {
    color: white;
    font-size: 30px;
    font-family: 'Cinzel', serif;
    margin-top: 30px;
    margin-bottom: 15px;
}

.informacion h6 {
    margin: 10px;
}

.informacion span {
    font-weight: 800;
}


.formulario {
    /* margin-top: -150px; */
    display: flex;
    flex-direction: column;
    width: 80%;
    align-items: flex-start;
    margin-left: 10px;

}

.pantalla {
    background-color: rgba(8, 8, 8, 0.5);
    box-shadow: inset 0px -127px 51px 0px rgba(14, 14, 14, 0.1);
    width: 100%;
    height: 90vh;
    display: flex;
    flex-wrap: wrap;
}


@media(min-width:48rem) {
    .informacion {
        width: 50%;
    }

    .formulario {
        margin-top: 0px;
        width: 45%;
    }
}