.navbar {
    display: flex;
    flex-wrap: wrap;
    margin-top: -60px;

}

.secciones {
    display: flex;
    flex-wrap: wrap;
    width: 80%;
    margin: 0 auto;
    background-color: rgb(54, 53, 53);
    border-radius: 5px;
    box-shadow: 0px 10px 19px -3px rgb(105, 105, 105);
}

li {
    display: flex;
    text-decoration: none;
    color: white;
    font-size: 20px;
    width: 50%;
    text-align: center;
    align-items: center;
    justify-content: center;
}

li a {
    color: white;
    padding: 20px;
    position: relative;
    cursor: pointer;

}

li a::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: linear-gradient(to right, #333333, #646464, #ffffff);
    z-index: 1;
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.5s ease-in-out;
}

li a:hover::before {
    transform: scaleX(1);
}



@media(min-width:37.500em) {}


@media(min-width:48rem) {

    .secciones {
        margin-top: 30px;

    }

    li {

        width: 25%;
    }


}

@media (min-width: 61.93em) {}